import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
var path = process.env.VUE_APP_BASE_API;
export default {
  name: 'Excel',
  data: function data() {
    return {
      path: path,
      fileList: [{
        name: 'food.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }, {
        name: 'food2.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }]
    };
  },
  computed: _objectSpread({}, mapGetters('user', ['userInfo', 'token'])),
  methods: {
    handleRemove: function handleRemove(file, fileList) {
      this.$message.warning("\u5171\u6709 ".concat(fileList.length, " \u4E2A\u6587\u4EF6\uFF0C\u79FB\u9664\u4E86").concat(file.name));
    },
    handlePreview: function handlePreview(file) {
      this.$message.warning("".concat(file.name, "\u9009\u62E9\u5B8C\u6210"));
    },
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u5F53\u524D\u9650\u5236\u9009\u62E9 3 \u4E2A\u6587\u4EF6\uFF0C\u672C\u6B21\u9009\u62E9\u4E86 ".concat(files.length, " \u4E2A\u6587\u4EF6\uFF0C\u5171\u9009\u62E9\u4E86 ").concat(files.length + fileList.length, " \u4E2A\u6587\u4EF6"));
    },
    beforeRemove: function beforeRemove(file, fileList) {
      return this.$confirm("\u5171\u6709 ".concat(fileList.length, " \u4E2A\u6587\u4EF6\uFF0C\u786E\u5B9A\u79FB\u9664 ").concat(file.name, "\uFF1F"));
    }
  }
};